import React from "react";
import axios from "axios";
import { Link } from "react-router-dom";

// reactstrap components
import {
  Button,
  Container,
  Row,
  Col,
} from "reactstrap";
import BookNavbar from "components/Navbars/BookNavbar";
import ProfilePageHeader from "components/Headers/ProfilePageHeader";

export default class CrystalDetailPage extends React.Component {
  state = {
    crystalDetail: [],
  };
  
  componentDidMount() {
    let apiURL =
      "https://www.libreriacollino.com.ar/api/crystals/" +
      this.props.match.params.name.toUpperCase();
    axios.get(apiURL).then((res) => {
      const crystalDetail = res.data;
      this.setState({ crystalDetail });
    });
  }

   render() {    
    const crystal = this.state.crystalDetail;  

    return (
      <>
        <BookNavbar />
        <ProfilePageHeader />
        <div className="section profile-content">
          <Container>
            <div className="owner">
              <div className="avatar">
                <img
                  alt="..."
                  className="img-circle img-no-padding img-responsive"
                  src={require(`assets/img/cristales/${this.props.match.params.name.toUpperCase()}.jpg`).default}
                />
              </div>
              <div className="name">
                <h2 className="title">
                  {crystal.name}
                  <br />
                </h2>
                <h6 className="description">{crystal.name}</h6>
              </div>
            </div>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="9">
                <p>
                  {crystal.description}
                </p>
                <br />
                <Button className="btn-round" color="success" outline to={`/crystals`} tag={Link}>
                   Volver
                </Button>
              </Col>
            </Row>
            <br />
          </Container>
        </div>
      </>
    );
  }
}
