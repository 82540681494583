/*!

=========================================================
* Paper Kit React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Link } from "react-router-dom"
// reactstrap components
import { Button, Container, Row, Col } from "reactstrap";

// core components
import Layout from "components/Layout.js";

function LandingPage() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("profile-page");
    return function cleanup() {
      document.body.classList.remove("profile-page");
    };
  });
  return (
    <>
      <Layout>
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="8">
                <h2 className="title text-warning">Cuando lees,</h2>
                <h5 className="description">Estas inspirando a alguien más.</h5>
                <br />
              </Col>
            </Row>
            <br />
            <br />
            <Row>
              <Col md="4">
                <div className="info">
                  <div className="icon icon-warning">
                    <i className="nc-icon nc-diamond" />
                  </div>
                  <div className="description">
                    <h4 className="info-title">Cristales</h4>
                    <p className="description">
                      Acercate a conocer más acerca de ellos, y a ver lo que
                      tenemos para ofrecer.
                    </p>
                    <Button className="btn-link" color="warning" to={`/crystals`} tag={Link}>
                      Ver mas
                    </Button>
                  </div>
                </div>
              </Col>
              <Col md="4">
                <div className="info">
                  <div className="icon icon-warning">
                    <i className="nc-icon nc-book-bookmark" />
                  </div>
                  <div className="description">
                    <h4 className="info-title">
                      Libros & artículos <br /> de oficina
                    </h4>
                    <p>
                      Tenemos las ultimas novedades en artículos de oficina, y
                      una gran variedad de títulos disponibles para leer.
                    </p>
                    <Button className="btn-link" color="warning" href="#">
                      Ver mas
                    </Button>
                  </div>
                </div>
              </Col>
              <Col md="4">
                <div className="info">
                  <div className="icon icon-warning">
                    <i className="nc-icon nc-chat-33" />
                  </div>
                  <div className="description">
                    <h4 className="info-title">Contacto</h4>
                    <p>Comunicate mediante nuestras redes sociales, conocenos y descubri como llegar!</p>
                    <Button className="btn-link" color="warning" href="#socials">
                      Ver mas
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
      </Layout>
    </>
  );
}

export default LandingPage;
