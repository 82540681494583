import React from "react";

import BookNavbar from "components/Navbars/BookNavbar";
import ProfilePageHeader from "components/Headers/ProfilePageHeader";
import BooksInfiniteScroll from "components/Card/BooksInfiniteScroll";
import GenericFooter from "components/Footers/GenericFooter";
import LandingFooter from "components/Footers/LandingFooter";

export default class BooksPage extends React.Component {
  render() {
    return (
      <>
        <BookNavbar />
        <ProfilePageHeader />
        <BooksInfiniteScroll />
        <LandingFooter/>
        <GenericFooter/>
      </>
    );
  }
}
