/*!

=========================================================
* Paper Kit React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

// styles
import "bootstrap/scss/bootstrap.scss";
import "assets/scss/paper-kit.scss?v=1.3.0";
import "assets/demo/demo.css";
// pages
import CrystalsPage from "views/CrystalPage/CrystalsPage.js";
import CrystalDetailPage from "views/CrystalPage/CrystalDetailPage.js";
import BooksPage from "views/BooksPage/BooksPage.js";
import LandingPage from "views/LandingPage/LandingPage.js";

// others

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route path="/index" render={(props) => <LandingPage {...props} />} />
      <Route
        exact
        path="/crystals"
        render={(props) => <CrystalsPage {...props} />}
      />
      <Route
        path="/crystals/:name"
        render={(props) => <CrystalDetailPage {...props} />}
      />
      <Route path="/books" render={(props) => <BooksPage {...props} />} />
      <Redirect to="/index" />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
