import React from "react";

// core components
import Crystals from "components/Card/Crystals.js";
import Layout from "components/Layout";
import { Link } from "react-router-dom";
import { Button, Container } from "reactstrap";

function CrystalsPage() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("profile-page");
    return function cleanup() {
      document.body.classList.remove("profile-page");
    };
  });

  return (
    <>
      <Layout>
        <Container>
          <h2 className='tittle text-warning'>Conoce mas acerca</h2>
          <h5 className="description">de nuestros cristales</h5>
        <Crystals />
        <Button
          className="btn-round"
          color="success"
          outline
          to={`/index`}
          tag={Link}
        >
          Volver
        </Button>
        </Container>
      </Layout>
    </>
  );
}

export default CrystalsPage;
