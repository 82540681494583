import React from "react";

const Loading = () => {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("profile-page");
    return function cleanup() {
      document.body.classList.remove("profile-page");
    };
  });
  return (
    <>
      <div
        className="blur"
        style={{
          width: "100%",
          height: "100%",
          position: "fixed",
          top: "0",
          left: "0",
          backgroundColor: "#fff",
          opacity: "0.60",
          zIndex: "1001",
        }}
      ></div>
            <div
        className="blur"
        style={{
          position: "fixed",
          top: "30%",
          left: "50%",
          zIndex: "1002",
          transform: "translate(-50%, -50%)"
        }}
      ><h2 className='text-dark'>Cargando ...</h2></div>
      <div className="loader">   
        <img
          src={require(`../assets/img/loading.gif`).default}
          alt="lag"
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            zIndex: "1003",
            transform: "translate(-50%, -50%)"
          }}
        />
      </div>
    </>
  );
};

export default Loading;
