import React from "react";

var MapBanner = function MapBanner() {
  const borderMap = {border: 0}
  return (
    <div>
      <iframe 
      title="Map"
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1303.1692057413272!2d-62.08977903330682!3d-31.428027746514033!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95cb28162ea9f9f5%3A0xbb804a6fc65a6a5c!2sLibrer%C3%ADa%20Collino!5e0!3m2!1ses-419!2sar!4v1631728055297!5m2!1ses-419!2sar" 
      width="100%" 
      height="450" 
      allowFullScreen="" 
      loading="lazy"
      style={borderMap}>
      </iframe>
    </div>
  );
}

export default MapBanner;
