import React from "react";

// core components
import BookNavbar from "components/Navbars/BookNavbar.js";
import LandingPageHeader from "components/Headers/LandingPageHeader.js";

import LandingFooter from "components/Footers/LandingFooter.js";
import GenericFooter from "components/Footers/GenericFooter.js";

const Layout = ({ children }) => {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("profile-page");
    return function cleanup() {
      document.body.classList.remove("profile-page");
    };
  });
  return (
    <>
      <BookNavbar />
      <LandingPageHeader />
      <div className="main">
        <div className="section text-center">
          {children}
        </div>
      </div>
      <LandingFooter/>
      <GenericFooter />
    </>
  );
};

export default Layout;
