/*eslint-disable*/
import React from "react";

// reactstrap components
import { Button, Row, Col, Container } from "reactstrap";
import MapBanner from "components/Maps/MapBanner.js";

function LandingFooter() {
  return (
    <>
      <MapBanner id="mapBanner" />
      <div className="section bg-dark text-center">
        <Container>
          <h2 className="title text-light">Acerca de nosotros</h2>
          <Row>
            <Col className="ml-auto mr-auto" md="8">
              <h6 id="socials" className="card-category">
                Libreria Collino
              </h6>
              <p className="card-description text-center text-light">
                Somos una librería ubicada en San Francisco - Cordoba, nos
                orgullece nuestro equipo de trabajo, siempre estamos en la
                busqueda de mejorar, tanto nuestra atención comercial, como
                nuestra calidad humana.
                <br />
                <br />
                Gracias por acompañarnos.
              </p>
              <Button
                className="btn-just-icon btn-neutral"
                color="link"
                href="https://api.whatsapp.com/send?phone=5493564209554"
                target="_blank"
              >
                <i className="fa fa-whatsapp" />
              </Button>
              <Button
                className="btn-just-icon btn-neutral ml-1"
                color="link"
                href="https://es-la.facebook.com/libreriacollino"
                target="_blank"
              >
                <i className="fa fa-facebook-square" />
              </Button>
              <Button
                className="btn-just-icon btn-neutral ml-1"
                color="link"
                href="https://www.instagram.com/libreriacollino/"
                target="_blank"
              >
                <i className="fa fa-instagram" />
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default LandingFooter;
