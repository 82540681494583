import React from "react";
import axios from "axios";
//import CrystalsDataService from "../../services/crystals.services";
import {
  Button,
  Row,
  Col,
  Card,
  CardImg,
  CardBody,
  Container,
} from "reactstrap";
import { Link } from "react-router-dom";

export default class Crystals extends React.Component {
  state = {
    crystals: [],
  };

  componentDidMount() {
    axios.get("https://www.libreriacollino.com.ar/api/crystals").then((res) => {
      const crystals = res.data;
      this.setState({ crystals });
    });
  }

  render() {
    return (
      <Container>
        <Row className="ml-auto mr-auto">
          {this.state.crystals.map((cristal) => (
            <Col key={cristal.name} md="4">
              <Card style={{ width: "18rem", marginTop: "25px" }}>
                <CardImg
                  top
                  src={
                    require(`../../assets/img/cristales/${cristal.name}.jpg`)
                      .default
                  }
                  alt=""
                  style={{width:"256px", height:"256px"}}
                  className="ml-auto mr-auto"
                />
                <CardBody>
                  <Button color="success" to={`/crystals/${cristal.name}`} tag={Link}>{cristal.name}</Button>
                </CardBody>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    );
  }
}
