import React from "react";
import axios from "axios";

import { InfiniteScroll } from "react-simple-infinite-scroll";

// reactstrap components
import {
  Row,
  Col,
  Container,
  Card,
  CardBody,
  CardHeader,
  CardText,
  CardImg,
  CardLink,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  FormGroup,
  Label,
  Button,
} from "reactstrap";
import Loading from "components/Loading";

export default class BooksInfiniteScroll extends React.Component {
  state = {
    title: "",
    titleSearch: false,
    booksList: [],
    isLoading: true,
    cursor: 0,
  };

  componentDidMount() {
    this.loadMore();
  }

  loadMore = async () => {
    this.setState({ isLoading: true, error: undefined });

    //let apiURL = `https://www.libreriacollino.com.ar/api/books?page=${this.state.cursor}`;
    let apiURL;

    this.state.title !== ""
      ? (apiURL = `https://www.libreriacollino.com.ar/api/books?title=${this.state.title}`)
      : (apiURL = `https://www.libreriacollino.com.ar/api/books?page=${this.state.cursor}`);
    await new Promise(r => setTimeout(r, 500));
    await axios.get(apiURL).then(
      (res) => {
        //const booksList = res.data;
        if (this.state.title !== "") {
          this.setState({
            booksList: [...res.data],
            isLoading: false,
            cursor: 0,
          });
        } else {
          let cur = this.state.cursor + 1;
          this.setState({
            booksList: [...this.state.booksList, ...res.data],
            isLoading: false,
            cursor: cur,
          });
        }
      },
      (error) => {
        this.setState({
          isLoading: false,
          error,
        });
      }
    );
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    await this.setState({
      title: event.target.title.value,
      booksList: [],
    });
    this.loadMore();
  };

  render() {
    return (
      <>
        <Container
          style={{
            marginTop: "25px",
            marginBottom: "25px",
          }}
        >
          <Row>
            <Col md="4" className="ml-auto mr-auto">
              <form onSubmit={this.handleSubmit}>
                <FormGroup>
                  <Label for="Search">Que estas buscando</Label>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="nc-icon nc-zoom-split"></i>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      type="text"
                      placeholder="Buscar titulo o autor"
                      name="title"
                    />
                    <Button color="primary" type="submit">
                      Buscar
                    </Button>
                  </InputGroup>
                </FormGroup>
              </form>
            </Col>
          </Row>
        </Container>
        <Container>
          <InfiniteScroll
            throttle={64}
            threshold={300}
            isLoading={this.state.isLoading}
            hasMore={!!this.state.cursor}
            onLoadMore={this.loadMore}
          >
            <Row className="ml-auto mr-auto">
              {this.state.booksList.length > 0 ? (
                this.state.booksList.map((book) => (
                  <Col md="3" key={book.id}>
                    <Card>
                      <CardImg
                        top
                        src={
                          require(`../../assets/img/default-book.png`).default
                        }
                        alt={book.title}
                      />
                      <CardHeader>{book.title}</CardHeader>
                      <CardBody>
                        <CardText>
                          ISBN:{book.isbn}
                          <br />
                          AUTHOR:{book.author}
                        </CardText>
                      </CardBody>
                      <CardHeader>
                        <CardLink
                          data-placement="bottom"
                          href={`https://api.whatsapp.com/send?phone=5493564209554&text=Consulta%3A%0D%0A%0D%0A%F0%9F%93%9ALibro%3A+${book.title}%0D%0A%F0%9F%94%8EISBN%3A+${book.isbn}%0D%0A%E2%9C%8D%EF%B8%8FAutor%3A+${book.author}%0D%0A%0D%0A`}
                          target="_blank"
                          title="Contactanos por whatsapp"
                        >
                          
                          <h5 className="text-center">
                          Consultar<i className="fa fa-whatsapp"/></h5>
                        </CardLink>
                      </CardHeader>
                    </Card>
                  </Col>
                ))
              ) : (
                <h5
                  className="ml-auto mr-auto"
                  style={{ margin: "50px", marginBottom: "150px" }}
                >
                  No se encontraron resultados.
                </h5>
              )}
            </Row>
          </InfiniteScroll>
          {this.state.isLoading && <Loading key="Lag" />}
        </Container>
      </>
    );
  }
}
